/*=========================================================================================
  File Name: moduleLanguage.js
  Description: Language Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from './moduleCityState.js'
import mutations from './moduleCityMutations.js'
import actions from './moduleCityActions.js'
import getters from './moduleCityGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations, 
    actions: actions,
    getters: getters
}
