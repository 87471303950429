<template>
  <div id="data-list-list-view">
    <vs-prompt
      v-bind:title="$t('New')"
      @cancel="clearHospitalRoomModel"
      @accept="saveHospitalRoomModel"
      @close="clearHospitalRoomModel"
      :is-valid="validateHospitalRoomModel"
      :active.sync="activePrompt"
      :cancel-text="$t('Cancel')"
      :accept-text="$t('Save')"
    >
      <VuePerfectScrollbar
        class="scroll-area p-4"
        :settings="settings"
        :key="$vs.rtl"
      >
        <form @submit.prevent style="max-height:75vh;">
          <label for="from" class="vs-input--label"> {{ $t("Room Info EN") }}</label>
          <vs-input
            class="w-full mt-4"
           
            v-model="HospitalRoomModel.NameEN"
            name="TitleEN"
          />

          <label for="from" class="vs-input--label"> {{ $t("Room Info Ar") }}</label>
          <vs-input
            class="w-full mt-4"
            v-model="HospitalRoomModel.NameAR"
            name="TitleAR"
          />

       
      
        </form>
      </VuePerfectScrollbar>
    </vs-prompt>

    <div>
      <vx-card class="flex-1" v-bind:title="$t('Hospital Rooms')">
        <vs-button
          type="border"
          class="mb-4 mt-4"
          icon-pack="feather"
          icon="icon-plus"
          @click="activePrompt = true"
        >
          {{ $t("Add New") }}
        </vs-button>
        <vs-table
          ref="table"
          pagination
          :max-items="5"
          :data="HospitalModel.HospitalRooms"
        >
          <template slot="thead">
            <vs-th> {{ $t("Room Info  EN") }}</vs-th>
            <vs-th> {{ $t("Room Info  AR") }}</vs-th>
     
            <vs-th> {{ $t("Action") }}</vs-th>
          </template>

          <template slot-scope="{ data }">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <p class="product-name font-medium truncate">
                    {{ tr.NameEN }}
                  </p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium truncate">
                    {{ tr.NameAR }}
                  </p>
                </vs-td>
             
                <vs-td class="whitespace-no-wrap">
                  <vs-button
                    class="text-lg mx-2"
                    color="danger"
                    @click.stop="openConfirm(tr)"
                    >{{ $t("Delete") }}</vs-button
                  >
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </vx-card>
    </div>
  </div>
</template>
<script>
// import vSelect from "vue-select";
import { domain, mainSetting } from "@/gloabelConstant.js";

import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Datepicker from "vuejs-datepicker";
// import moment from "moment";

export default {
  components: {
    VuePerfectScrollbar,
    Datepicker

    // vSelect,
  },
  props: {
    HospitalModel: {
      type: Object,
      required: true
    }
  },
  computed: {
    validateHospitalRoomModel() {
      return (
        this.HospitalRoomModel.NameEN != undefined &&
        this.HospitalRoomModel.NameAR != undefined 
   
      );
    },
 
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3,
        years: []
      },
      isMounted: false,
      rowDataForDelete: {},
      HospitalRoomModel: {},
      activePrompt: false,
   //   UploadRequestHeader: mainSetting.UploadRequestHeader,
      baseURL: domain,
      uploadUrl: domain + "API/Common/UploadFile",
    };
  },
  methods: {
    
    refresh() {
   
    },
    clearHospitalRoomModel() {
      this.activePrompt = false;
      this.HospitalRoomModel = {};
    },
    saveHospitalRoomModel() {
      this.HospitalModel.HospitalRooms.push(this.HospitalRoomModel);
      this.clearHospitalRoomModel();
    },
    openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("DeleteConfirmText"),
        accept: this.acceptAlert,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel")
      });
    },

    acceptAlert() {
      this.deleteHospitalRoom(this.rowDataForDelete);

      window.showDeleteSuccess();
    },
    deleteHospitalRoom(data) {
      if (data.ID > 0) {
        // this.$store.dispatch("SubjectExamRoleRuleList/removeSubjectExamRoleRole",  data);
        this.$store.dispatch("HospitalList/removeHospitalRoom", data);
      }

      const ItemIndex = this.HospitalModel.HospitalRooms.findIndex(
        p => p.NameEN == data.NameEN
      );
      this.HospitalModel.HospitalRooms.splice(ItemIndex, 1);
    }
  },
  created() {
   
  }
};
</script>
<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
