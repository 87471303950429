<template>
  <div>
    <div class="vx-row">
      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("NameEn") }}*</label>
        <vs-input  v-model="HospitalModel.NameEN" class="w-full" v-validate="'required'" />
        <span class="text-danger text-sm" v-show="errors.has('FullNameEN')">{{
          errors.first("FullNameEN")
        }}</span>
      </div>

      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("NameAr") }}</label>
        <vs-input
          v-model="HospitalModel.NameAR"
          v-validate="'required'"
          class="w-full"
          name="FullNameAR"
        />
        <span class="text-danger text-sm" v-show="errors.has('FullNameAR')">{{
          errors.first("FullNameAR")
        }}</span>
      </div>
      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("Country") }}*</label>
        <v-select
          label="Name"
          name="Country"
          class="mw-full"
          v-model="HospitalModel.CountryID"
          :options="countries"
          :reduce="(ID) => ID.ID"
        />
      </div>

      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("City") }}</label>
        <v-select
          label="Name"
          name="City"
          class="mw-full"
          v-model="HospitalModel.CityID"
          :options="cities"
          :reduce="(ID) => ID.ID"
        />
      </div>
    </div>

    <div class="vx-row mt-5">
      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("PhoneNumber") }}</label>
        <vs-input
          v-model="HospitalModel.Phone"
          class="w-full"
          name="Phone"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('Phone')">{{
          errors.first("Phone")
        }}</span>
      </div>

      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("Address") }}</label>
        <vs-input v-model="HospitalModel.Address" class="w-full" name="Address" />
      </div>

      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("Email") }}</label>
        <vs-input v-model="HospitalModel.Email" class="w-full" name="Email" />
      </div>

      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("Contact Person Email") }}</label>
        <vs-input
          v-model="HospitalModel.ContactPersonEmail"
          class="w-full"
          name="ContactPersonEmail"
        />
      </div>
    </div>
    <div class="vx-row mt-5">
      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("Contact Person Name") }}</label>
        <vs-input
          v-model="HospitalModel.ContactPersonName"
          class="w-full"
          name="ContactPersonName"
        />
      </div>
      <div class="vx-col lg:w-1/6">
        <label class="vs-input--label">{{ $t("Number of rooms") }}</label>
        <vs-input
          v-model="HospitalModel.NumberOfRooms"
          class="w-full"
          name="NumberOfRooms"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('NumberOfRooms')">{{
          errors.first("NumberOfRooms")
        }}</span>
      </div>
      <div class="vx-col lg:w-1/4">
        <label class="vs-input--label">{{ $t("Hospital Group") }}</label>
        <v-select
          label="Name"
          name="City"
          class="mw-full"
          v-model="HospitalModel.HospitalGroupID"
          :options="ListOfHospitalGroups"
          :reduce="(ID) => ID.ID"
        />
      </div>

      <div class="vx-col lg:w-1/3">
        <label class="vs-input--label">{{ $t("specialities") }}</label>
        <v-select
          label="Name"
          multiple
          v-model="specialities"
          :options="ListOfSpecialities"
          :reduce="(ID) => ID.ID"
        />
      </div>
      <div class="vx-col lg:w-1/2 w-full mt-2">
        <label class="vs-input--label">{{ $t("Hospital Rooms AR") }}</label>
        <vs-textarea
          name="HospitalRooms"
          style="height: 8rem; overflow-x: auto !important"
          rows="10"
          v-model="HospitalModel.RoomDescriptionAR"
        ></vs-textarea>
      </div>
      <div class="vx-col lg:w-1/2 w-full mt-2">
        <label class="vs-input--label">{{ $t("Hospital Rooms EN") }}</label>
        <vs-textarea
          name="HospitalRooms"
          style="height: 8rem; overflow-x: auto !important"
          rows="10"
          v-model="HospitalModel.RoomDescriptionEN"
        ></vs-textarea>
      </div>
      <div class="vx-col lg:w-1/2 w-full mt-2">
        <label class="vs-input--label">{{ $t("Hospital Info AR") }}</label>
         <quill-editor v-model="HospitalModel.HospitalInfoAR" :options="editorOption" />
        <!-- <vs-textarea
          name="DescriptionEN"
          style="height: 8rem; overflow-x: auto !important"
          v-model="HospitalModel.HospitalInfo"
        ></vs-textarea> -->
      </div>
       <div class="vx-col lg:w-1/2 w-full mt-2">
        <label class="vs-input--label">{{ $t("Hospital Info EN") }}</label>
         <quill-editor v-model="HospitalModel.HospitalInfoEN" :options="editorOption" />
        <!-- <vs-textarea
          name="DescriptionEN"
          style="height: 8rem; overflow-x: auto !important"
          v-model="HospitalModel.HospitalInfo"
        ></vs-textarea> -->
      </div>

      <div class="vx-col lg:w-1/2 w-full mt-2">
        <label class="vs-input--label">{{ $t("Recommendation level") }}</label>
        <star-rating
          v-model="HospitalModel.RecommendedLevel"
          v-bind:star-size="30"
          :star-points="[
            23,
            2,
            14,
            17,
            0,
            19,
            10,
            34,
            7,
            50,
            23,
            43,
            38,
            50,
            36,
            34,
            46,
            19,
            31,
            17,
          ]"
          v-bind:show-rating="false"
        ></star-rating>


      </div>
    </div>
    <div class="vx-row mt-5" >
      <div class="vx-col lg:w-1/5 w-full">
        <vs-checkbox
          class="mt-1 w-full justify-left"
          v-model="HospitalModel.IsAllowBankTransfare"
          >{{ $t("Is Allow Bank Transfare") }}
        </vs-checkbox>
      </div>
      <div class="vx-col lg:w-1/5 w-full" v-if="HospitalModel.IsAllowBankTransfare">
        <label class="vs-input--label">{{ $t("Account Number") }}</label>
        <vs-input
          v-model="HospitalModel.AccountNumber"
          class="w-full"
          name="AccountNumber"
        />
      </div>
      <div class="vx-col lg:w-1/5 w-full" v-if="HospitalModel.IsAllowBankTransfare">
        <label class="vs-input--label">{{ $t("Bank Name") }}</label>
        <vs-input v-model="HospitalModel.BankName" class="w-full" name="BankName" />
      </div>
      <div class="vx-col lg:w-1/5 w-full" v-if="HospitalModel.IsAllowBankTransfare">
        <label class="vs-input--label">{{ $t("Branch Address") }}</label>
        <vs-input

          v-model="HospitalModel.BranchAddress"
          class="w-full"
          name="BranchAddress"
        />
      </div>
      <div class="vx-col lg:w-1/5 w-full" v-if="HospitalModel.IsAllowBankTransfare">
        <label class="vs-input--label">{{ $t("IBAN") }}</label>
        <vs-input v-model="HospitalModel.IBAN" class="w-full" name="IBAN" />
      </div>
    </div>
    <div class="vx-row">
      <!-- profile img -->
      <div class="vx-col lg:w-1/4 w-full mt-8">
        <vs-card title="icon">
          <h4>{{ $t("Image") }}</h4>
          <template v-if="HospitalModel.ImagePath">
            <div class="img-container w-64 mx-auto flex items-center justify-center">
              <img
                :src="baseURL + HospitalModel.ImagePath"
                alt="img"
                class="responsive"
              />
            </div>
          </template>
          <div class="modify-img flex justify-between mt-5">
            <input
              type="file"
              class="hidden"
              ref="updateImgInput"
              @change="updateCurrImg"
              accept="image/*"
            />
            <vs-button class="mr-4" type="flat" @click="$refs.updateImgInput.click()">{{
              $t("UpdateImage")
            }}</vs-button>
            <vs-button type="flat" color="#999" @click="HospitalModel.ImagePath = null">{{
              $t("RemoveImage")
            }}</vs-button>
          </div>
        </vs-card>
      </div>
      <!-- gallary -->
      <div class="vx-col lg:w-3/4 w-full mt-3">
        <div>
          <p class="text-align-left">{{ $t("HospitalImages") }}</p>
        </div>
        <div class="vx-row w-full ml-5 mr-5 mb-3">
          <div
            v-for="item in HospitalModel.HospitalAttachments"
            :key="item.ID"
            class="lg:w-1/4 w-full"
          >
            <div>
              <div style="position: absolute">
                <feather-icon
                  icon="XIcon"
                  style="color: red"
                  svgClasses="w-10 h-10 hover:text-primary stroke-current"
                  @click.stop="removeImage(item)"
                />
              </div>
              <div>
                <img
                  style="border-radius: 10px; opacity: 1"
                  class="p-1"
                  :src="baseURL + item.AttachPath"
                  width="200"
                  height="200"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <input
            type="file"
            ref="uploadImgInput"
            @change="AttachmentUploaded"
            accept="image/*"
            @on-success="successUpload"
          />
        </div>
      </div>
    </div>

    <div class="vx-row"></div>
  </div>
</template>
<script>
import vSelect from "vue-select";
import { domain, mainSetting } from "@/gloabelConstant.js";
import moduleCountry from "@/store/settings/country/moduleCountry.js";
import moduleSpecialty from "@/store/settings/specialty/moduleSpecialty.js";
import moduleHospitalGroup from "@/store/settings/HospitalGroup/moduleHospitalGroup.js";
import moduleCity from "@/store/settings/city/moduleCity.js";
import Datepicker from "vuejs-datepicker";
import moduleHospital from "@/store/Hospital/moduleHospital.js";
import StarRating from "vue-star-rating";
import axios from "@/axios.js";
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
export default {
  components: {
    vSelect,
    Datepicker,
    StarRating,
    quillEditor
  },
  props: {
    HospitalModel: {
      type: Object,
      required: true,
    },
  },
  computed: {
    countries() {
      return this.$store.state.CountryList.countries;
    },

    ListOfHospitalGroups() {
      return this.$store.state.HospitalGroupList.HospitalGroups;
    },
    ListOfSpecialities() {
      return this.$store.state.SpecialtyList.specialities;
    },

    specialities: {
      get() {
        return this.$store.state.HospitalList.specialities;
      },

      set(newValue) {
        this.$store.commit("HospitalList/SET_specialities", newValue);
      },
    },
    cities() {
      return this.$store.state.CityList.cities;
    },
  },
  data() {
    return {
         editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike', 'link', 'blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'font': [] }],
          ],
        },
        placeholder: 'Message'
      },
      isMounted: false,
      ImagURL: null,
      baseURL: domain,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "API/Common/UploadFile",
    };
  },
  methods: {

    async updateCurrImg(input) {
      this.$vs.loading();
      debugger;
      if (input.target.files && input.target.files[0]) {
        const file = input.target.files[0];
      //  this.HospitalModel.ImagePath = response.data;
        this.$store
          .dispatch("HospitalList/UploadImage", file)
          .then((res) => {
            if (res.status == 200) {
              this.HospitalModel.ImagePath = res.data;
              //this.uploadUrl = res.data;
              this.$vs.loading.close();
              window.showSuccess();
            }
            this.$vs.loading.close();
          })
          .catch(() => {
            this.$vs.loading.close();
            window.showError();
          });
      }
    },
    removeImage(item) {
      var index = this.HospitalModel.HospitalAttachments.findIndex(
        (x) => x.AttachPath == item.AttachPath
      );
      this.HospitalModel.HospitalAttachments.splice(index, 1);
      if (item.ID > 0) this.$store.dispatch("HospitalList/DeleteAttachmenByID", item.ID);
    },
    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur",
      });
    },

    AttachmentUploaded(input) {
      this.$vs.loading();
      debugger;
      if (input.target.files && input.target.files[0]) {
        const file = input.target.files[0];
        this.$store
          .dispatch("HospitalList/UploadImage", file)
          .then((res) => {
            if (res.status == 200) {
           var temp = {};
           temp.AttachPath = res.data;
           this.HospitalModel.HospitalAttachments.push(temp);
              this.$vs.loading.close();
              window.showSuccess();
            }
            this.$vs.loading.close();
          })
          .catch(() => {
            this.$vs.loading.close();
            window.showError();
          });
      }
    },

    // AttachmentUploaded(input) {
    //   debugger;
    //   if (input.target.files && input.target.files[0]) {
    //     let data = new FormData();
    //     data.append("file", input.target.files[0]);

    //     axios.post(this.uploadUrl, data).then((response) => {
    //       var temp = {};
    //       temp.AttachPath = response.data;
    //       this.HospitalModel.HospitalAttachments.push(temp);
    //     });
    //   }
    // },

    // updateCurrImg(input) {
    //   debugger;
    //   this.$store.dispatch("updateCurrImg", input).then((response) => {
    //     this.HospitalModel.ImagePath = response.data;
    //   });
    // },
    refresh() {
      this.$store.commit("HospitalList/SET_specialities", this.specialities);
    },
  },
  created() {
    if (!moduleHospital.isRegistered) {
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }
    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }

    if (!moduleHospitalGroup.isRegistered) {
      this.$store.registerModule("HospitalGroupList", moduleHospitalGroup);
      moduleHospitalGroup.isRegistered = true;
    }
    if (!moduleSpecialty.isRegistered) {
      this.$store.registerModule("SpecialtyList", moduleSpecialty);
      moduleSpecialty.isRegistered = true;
    }
    if (!moduleCity.isRegistered) {
      this.$store.registerModule("CityList", moduleCity);
      moduleCity.isRegistered = true;
    }

    this.$store.dispatch("CityList/GetAllCities");

    this.$store.dispatch("HospitalGroupList/GetAllHospitalGroups");
    this.$store.dispatch("SpecialtyList/GetAllSpecialties");
    this.$store.dispatch("CountryList/GetAllCountries");
  },
};
</script>
<style></style>
