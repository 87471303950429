/*=========================================================================================
  File Name: moduleQuestionBank.js
  Description: QuestionBank Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import state from "./moduleHospitalGroupState.js";
import mutations from "./moduleHospitalGroupMutations.js";
import actions from "./moduleHospitalGroupActions.js";
import getters from "./moduleHospitalGroupGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
