<template>
  <div>
    <feather-icon
      icon="ArrowLeftIcon"
      svgClasses="h-8"
      style="cursor: pointer"
      @click="$router.push('/Hospital')"
    />
    <vs-alert
      color="danger"
      title="Course Not Found"
      :active.sync="Hospital_not_found"
    >
      <span>Hospital record with id: {{ $route.params.Id }} not found. </span>
      <span>
        <span>Check </span
        ><router-link :to="{ name: 'Hospital' }" class="text-inherit underline"
          >All Hospital</router-link
        >
      </span>
    </vs-alert>
    <div>
      <hospital-Tab class="mt-4" :mainTabName="'profile'" />
      <vx-card>
        <div slot="no-body" class="tabs-container px-6 pt-6">
          <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
            <vs-tab :label="$t('Profile')" icon-pack="feather" icon="icon-user">
              <div class="tab-text">
                <hospital-tab-main
                  class="mt-4"
                  :HospitalModel="HospitalModel"
                />
              </div>
            </vs-tab>

            <!-- <vs-tab
              :label="$t('Hospital Rooms')"
              icon-pack="feather"
              icon="icon-square"
            >
              <div class="tab-text">
                <hospital-tab-rooms class="mt-4" :HospitalModel="HospitalModel" />
              </div>
            </vs-tab> -->
            <vs-tab
              :label="$t('Hospital Facilities')"
              icon-pack="feather"
              icon="icon-square"
            >
              <div class="tab-text">
                <hospital-tab-Facilities
                  class="mt-4"
                  :HospitalModel="HospitalModel"
                />
              </div>
            </vs-tab>
          </vs-tabs>
        </div>
      </vx-card>
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <vs-button
              class="mr-6"
                 :disabled="!isFormValid"
              @click="ConfirmUser"
              v-if="CanWrite && !HospitalModel.IsConfirmed"
              >{{ $t("Confirm") }}</vs-button
            >
            <vs-button
              class="mr-6"
              :disabled="!isFormValid"
              @click="SaveHospital"
              v-if="CanWrite"
              >{{ $t("Save") }}</vs-button
            >
            <vs-button
              class="mr-6"
              :disabled="!isFormValid"
              @click="SaveAndSendMailHospital"
              v-if="CanWrite"
              >{{ $t("Save & Send Mail") }}</vs-button
            >
            <vs-button
              type="border"
              color="danger"
              :to="{ name: 'Hospital' }"
              >{{ $t("Cancel") }}</vs-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HospitalTabMain from "./HospitalTabMain.vue";
import HospitalTabRooms from "./HospitalTabRooms.vue";
import HospitalTabFacilities from "./HospitalTabFacilities.vue";

import moduleHospital from "@/store/Hospital/moduleHospital.js";
import hospitalTab from "@/views/tabs/hospitalTab.vue";

export default {
  components: {
    HospitalTabMain,
    HospitalTabRooms,
    HospitalTabFacilities,
    hospitalTab,
  },
  data() {
    return {
      Hospital_not_found: false,
      CanWrite: false,
      activeTab: 0,
 
      HospitalModel: {
        specialities: [],
        HospitalSpecialties: [],
        facilities: [],
        HospitalFacilities: [],

        HospitalRooms: [],

        mainFocus: [],
        HospitalAttachments:[],
      },
    };
  },
  computed: {
    isFormValid() {
      return !this.errors.any() && this.HospitalModel.NameEN && this.HospitalModel.CountryID ;
    },
    filterHospitalAttachments() {
      return this.HospitalModel.HospitalAttachments.filter((obj) => obj.ID > 0);
    },
  },
  methods: {
    initValues() {
      this.HospitalModel.HospitalGroups = {};
      this.HospitalModel.specialities = [];
      this.HospitalModel.HospitalFacilities = [];
      this.HospitalModel.HospitalAttachments=[];
    },
    ConfirmUser() {
      debugger;
      this.HospitalModel;
      debugger;
      this.$store
        .dispatch("HospitalList/ConfirmUser", this.HospitalModel.UserID)
        .then((res) => {
          window.showSuccess();
          console.error(res);
          this.$vs.loading.close();

          this.initHospitalModelValues();
        })
        .catch((err) => {
          this.$vs.loading.close();
          console.error(err);
        });

      this.$router.push({ name: "Hospital" });
    },
    SaveHospital() {
      this.submitData(1);
    },
    SaveAndSendMailHospital() {
      this.submitData(2);
    },
    submitData(flag) {
      debugger;

      this.HospitalModel.HospitalSpecialties =
        this.$store.state.HospitalList.specialities.map(function (val) {
          var obj = {};
          obj.SpecialtyID = val;
          return obj;
        });

      if (this.HospitalModel.ID !== null && this.HospitalModel.ID >= 0) {
        this.$vs.loading();
        this.$store
          .dispatch("HospitalList/UpdateHospital", this.HospitalModel)
          .then((res) => {
            console.error(res);
            this.$vs.loading.close();
            window.showSuccess(res.data.message);
            this.$router.push({ name: "Hospital" });

            this.initHospitalModelValues();
          })
          .catch((err) => {
            this.$vs.loading.close();
            console.error(err);
            window.showError(err.response.data.message);
          });
      } else {
        // add hospital
        delete this.HospitalModel.ID;
        this.HospitalModel.popularity = 0;
        this.HospitalModel.UserType = "Hospital";
        this.HospitalModel.flag = flag;
        debugger;
        this.$vs.loading();
        this.$store
          .dispatch("HospitalList/AddHospital", this.HospitalModel)
          .then((res) => {
            console.error(res);
            this.$vs.loading.close();
            window.showSuccess(res.data.message);
            this.$router.push({ name: "Hospital" });
            debugger;
            //  this.$store.state.courseList.search.pageNum=1;
            this.initHospitalModelValues();
          })
          .catch((err) => {
            this.$vs.loading.close();
            console.error(err);
            window.showError(err.response.data.message);
          });
      }
      debugger;
    },
    initHospitalModelValues() {
      this.HospitalModel.HospitalGroups = {};
      this.HospitalModel.HospitalSpecialties = [];
      this.$store.commit("HospitalList/SET_specialities", []);
      //this.$store.commit("HospitalGroupsList/SET_HospitalGroups", {});
    },
  },
  created() {
    this.CanWrite =
      JSON.parse(localStorage.getItem("userInfo")).CanWrite ||
      JSON.parse(localStorage.getItem("userInfo")).userRole == "admin";
      JSON.parse(localStorage.getItem("userInfo")).userRole == "accountmanager";

    this.initValues();

    if (!moduleHospital.isRegistered) {
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }
    this.initHospitalModelValues();

    // var vm = this;
    const ID = this.$route.params.ID;
    if (ID != undefined) {
      this.$store.dispatch("HospitalList/GetHospital", ID).then((res) => {
        debugger;
        this.HospitalModel = res.data.Data;
        debugger
        this.HospitalModel.specialities =
          this.HospitalModel.HospitalSpecialties.map((a) => a.SpecialtyID);
        this.$store.commit(
          "HospitalList/SET_specialities",
          this.HospitalModel.specialities
        );
      });
    }
  },
};
</script>
