/*=========================================================================================
  File Name: moduleQuestionBankMutations.js
  Description: HospitalGroup Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.HospitalGroups.unshift(item);
  },
  SET_HospitalGroups(state, HospitalGroups) {
    state.HospitalGroups = HospitalGroups;
  },
  UPDATE_HospitalGroup(state, HospitalGroup) {
    const QuestionBankIndex = state.HospitalGroups.findIndex(
      p => p.ID == HospitalGroup.ID
    );
    if (QuestionBankIndex != -1) {
      Object.assign(state.HospitalGroups[QuestionBankIndex], HospitalGroup);
    }
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.HospitalGroups.findIndex(p => p.ID == itemId);
    state.HospitalGroups.splice(ItemIndex, 1);
  },

  SET_SubjectModules(state, SubjectModules) {
    state.SubjectModules = SubjectModules;
  }
};
