<template>

 
    <div class="ml-10 mr-10">

      
      <div class="w-full justify-center ">
  
          <div class="vx-row justify-center">
            <div v-for="item in facilities" :key="item.ID" class="vx-row justify-left xl:w-1/2 sm:w-1/4 mt-5">
              <vs-checkbox class="vx-col lg:w-1/2" v-model="item.IsChecked">{{
               item.Name
              }}</vs-checkbox>
            
              <img
                class="h-12 vx-col lg:w-1/4 "
                alt=""
                :src="baseURL+item.ImagePath"
              />
            </div>
          </div>
    
        </div>
           <div class="w-full mt-10 ">
            <div class="vx-col lg:w-3/4"></div>
            <vs-button
              color="rgb(30, 20, 79) "
              class="vx-col"
              type="filled"
              
              @click="UpdateHospital"
              >{{$t("Submit")}}</vs-button
            >
          </div>
   
      </div>
    
  
</template>
<script>


import moduleFacility from "@/store/settings/facility/moduleFacility.js";

// import axios from "@/axios.js"
import { domain,mainSetting } from '@/gloabelConstant.js';

export default {
  components: {

 
  },
  data() {
    return {
       baseURL : domain,
         UploadRequestHeader : mainSetting.UploadRequestHeader,
       uploadUrl : domain+"api/Common/UploadFile",
     
        HospitalFacilities:[],
    options: [],
      radios1: "luis"
    };
  },
  props: {
    HospitalModel: {
      type: Object,
      required: true,
    },
  },
  methods: {
 UpdateHospital()
    {
       this.HospitalModel.HospitalFacilities = this.facilities.filter((obj)=>obj.IsChecked).map((b)=>{
         //  function(val) {
          var obj = {};
          obj.FacilityID = b.ID;
          obj.HospitalID =this.HospitalModel.ID;
      
          return obj;
        
      });
  this.$vs.dialog({
        type: "alert",
        color: "success",
        title: this.$t("done"),
        text: this.$t("already check must Click save to submit "),
        accept: this.acceptAlert,
        acceptText: this.$t("Ok"),
      
      });

  
    }
  },
  computed:{
   
    facilities()
    {
       return this.$store.state.FacilityList.facilities;
    }
  },
  created()
  {
    
   if(!moduleFacility.isRegistered) {
      this.$store.registerModule("FacilityList", moduleFacility);
      moduleFacility.isRegistered = true;
    }

    this.$store.dispatch("FacilityList/GetAllFacilities", this.HospitalModel.ID).then(() => {
    
     } );
   
   

  }
};
</script>
<style>
.circle {
  width: 10px;
  height: 10px;
  background: #5DBEFF;
  border-radius: 50%;
}
.textfont {
  font-family: "futuraMedium";
}
.material-icons 
{
  font-family: 'Material Icons'!important;
}
.material-icons  :lang(en)
{
  font-family:unset
}
</style>
