/*=========================================================================================
  File Name: moduleSpecialty.js
  Description: Specialty Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from './moduleSpecialtyState.js'
import mutations from './moduleSpecialtyMutations.js'
import actions from './moduleSpecialtyActions.js'
import getters from './moduleSpecialtyGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
