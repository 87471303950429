/*=========================================================================================
  File Name: moduleSpecialtyMutations.js
  Description: Specialty Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.specialities.unshift(item)
  },
  SET_Specialty(state, specialities) {
    state.specialities = specialities
  },
  UPDATE_Specialty(state, Specialty) {
    const SpecialtyIndex = state.specialities.findIndex((p) => p.ID == Specialty.ID)
    Object.assign(state.specialities[SpecialtyIndex], Specialty)
},
SET_ParentSpecialty(state, specialties)
  {
    state.parentSpecialties = specialties
  },
  SET_ChildSpecialty(state, specialties)
  {
    state.childSpecialties = specialties
  },
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.specialities.findIndex((p) => p.ID == itemId)
    state.specialities.splice(ItemIndex, 1)
},
}
