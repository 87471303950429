/*=========================================================================================
  File Name: moduleFacilityMutations.js
  Description: Facility Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.facilities.unshift(item)
  },
  SET_Facilities(state, facilities) {
    state.facilities = facilities
  },
  UPDATE_Facility(state, facility) {
    const facilityIndex = state.facilities.findIndex((p) => p.ID == facility.ID)
    Object.assign(state.facilities[facilityIndex], facility)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.facilities.findIndex((p) => p.ID == itemId)
    state.facilities.splice(ItemIndex, 1)
},
}
